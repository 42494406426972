<template>
  <div class="m-5 p-3">
    <el-row>
      <el-col :md="15" class="pr-4">
        <div>
          <h2>{{ $t("SATMathLevel2.title") }}</h2>
          <div v-html="$t('SATMathLevel2.desc')"></div>
        </div>
      </el-col>
      <el-col :md="9" class="img">
        <el-image
          src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/math2c.jpg"
        />
      </el-col>
    </el-row>
    <div>
      <h4 style="font-weight:bold;">{{ $t("SATMathLevel2.schedule") }}</h4>
      <ul>
        <li>{{ $t("SATMathLevel2.s1") }}</li>
        <li>{{ $t("SATMathLevel2.s2") }}</li>
        <li style="list-style-type: none;">{{ $t("SATMathLevel2.s3") }}</li>
      </ul>
    </div>
    <div class="mt-4">
      <h4 style="font-weight:bold;">{{ $t("SATMathLevel2.tuition") }}</h4>
      <ul>
        <li v-html="$t('SATMathLevel2.t1')"></li>
        <li v-html="$t('SATMathLevel2.t2')"></li>
      </ul>
    </div>
    <div class="mt-4">
      <h4 style="font-weight:bold;">{{ $t("SATMathLevel2.materials") }}</h4>
      <ul>
        <li v-html="$t('SATMathLevel2.m1')"></li>
        <li v-html="$t('SATMathLevel2.m2')"></li>
        <li v-html="$t('SATMathLevel2.m3')"></li>
        <li v-html="$t('SATMathLevel2.m4')"></li>
        <li v-html="$t('SATMathLevel2.m5')"></li>
      </ul>
    </div>
    <div class="mt-4" style="display: flex; justify-content: center;">
      <el-button
        @click="toRoute('EnrollmentNew')"
        style="width:50%; min-width: 120px;"
        type="primary"
        >{{ $t("SATCoursePart2.enroll") }}</el-button
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.img {
  padding-left: 20px;
}

@media (max-width: 992px) {
  .img {
    padding-top: 20px;
    padding-left: 20px;
  }
}
</style>
